import { Auth, API } from 'aws-amplify';
import configData from '@/config/config.json';
import store from '@/store/store-modules';

export async function getCustomerFeedback() {
  return API.get('omw', `/feedback`);
}

// async function signup(userData) {
//   const { user } = await Auth.signUp({
//       username: userData.email,
//       password: userData.password,
//       attributes: {
//           email: userData.email
//       }
//   });
//   await store.dispatch('storeAuth/setAuthUser', user);
//   console.log(user);
// }

// async function verifyCode(email, code) {
//   await Auth.confirmSignUp(
//     email, code
//   );
//   await store.dispatch('storeAuth/setAuthUser', user);
//   console.log(user);
// }

// async function resendVerificationCode(email) {
//   const { user } = await Auth.resendSignUp({
//       username: userData.email,
//   });
//   await store.dispatch('storeAuth/setAuthUser', user);
//   console.log(user);
// }
