<!--
  Component to allow the upload of feedback data from Hotjar to link it to appointment data
-->
<template>
  <div class="container is-fluid" style="padding-top: 10px">
    <b-loading :is-full-page="isFullPage" :active.sync="isLoading"></b-loading>
    <b-field>
      <b-input
        v-model="searchCriteria"
        :expanded="false"
        size="is-medium"
        type="search"
        icon="search"
        :placeholder="$t('omw-feedback-filter-results-placeholder')"
        @input="search"
      ></b-input>
    </b-field>
    <b-field
      horizontal
      position="is-left"
      :label="$t('omw-feedback-date-range')"
    >
      <b-field expanded>
        <b-datepicker
          v-model="dateFrom"
          :placeholder="$t('omw-feedback-date-placeholder')"
          icon="calendar-alt"
          pack="fas"
          :date-formatter="$d"
          :first-day-of-week="1"
        ></b-datepicker>
      </b-field>
      <b-field expanded>
        <b-datepicker
          v-model="dateTo"
          :placeholder="$t('omw-feedback-date-placeholder')"
          icon="calendar-alt"
          pack="fas"
          :date-formatter="$d"
          :first-day-of-week="1"
        ></b-datepicker>
      </b-field>
      <b-button class="is-primary" @click="processData()"
        >Apply Date Filter</b-button
      >
      <b-field style="padding-top: 5px">
        <b-switch v-model="autoRefresh">{{
          $t('omw-feedback-auto-refresh-label')
        }}</b-switch>
      </b-field>
    </b-field>
    <b-field horizontal position="is-left">
      <div class="buttons" style="padding-bottom: 1em">
        <b-button
          type="is-primary"
          icon-left="download"
          @click="fetchAndProcessData()"
        >
          {{ $t('omw-feedback-refresh-data-label') }}
        </b-button>
        <b-button
          :disabled="feedback.length === 0"
          type="is-info"
          icon-left="download"
          @click="downloadCsv()"
        >
          {{ $t('omw-feedback-export-to-csv-label') }}
        </b-button>
      </div>
    </b-field>
    <div>
      <b-table
        :data="dateFilteredDataSet"
        :paginated="true"
        :per-page="5"
        style="overflow-x: scroll"
        striped
        detailed
        narrowed
        detail-key="feedbackDate"
        :show-detail-icon="true"
      >
        <template slot="empty">
          <p class="has-text-centered">{{ $t('omw-feedback-no-data') }}</p>
        </template>
        <template slot="detail" slot-scope="props">
          <div v-if="props.row.message" style="padding-bottom: 1rem">
            <p>
              <b class="has-text-primary">{{ $t('omw-feedback-message') }}:</b>
            </p>
            <p>{{ props.row.message }}</p>
          </div>
          <div v-if="props.row.parentResourceId" style="padding-bottom: 1rem">
            <p>
              <b class="has-text-primary"
                >{{ $t('omw-feedback-resource-parent') }}:</b
              >
            </p>
            <p>{{ props.row.parentResourceId }}</p>
          </div>

          <div v-if="props.row.userAgent" style="padding-bottom: 1rem">
            <p>
              <b class="has-text-primary"
                >{{ $t('omw-feedback-user-agent') }}:</b
              >
            </p>
            <p>{{ props.row.userAgent }}</p>
          </div>
        </template>
        <b-table-column
          v-slot="props"
          field="feedbackDate"
          :label="$t('omw-feedback-date')"
          sortable
          width="500"
          >{{
            adjustToLocalTimezone(props.row['feedbackDate'])
          }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="appointmentNumber"
          :label="$t('omw-feedback-appointment')"
          sortable
          >{{ props.row.appointmentNumber }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="resourceId"
          :label="$t('omw-feedback-engineer')"
          sortable
          >{{ props.row.resourceId || '' }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="rating"
          :label="$t('omw-feedback-rating')"
          sortable
          numeric
          centered
        >
          <b-icon
            :icon="getIcon(props.row.rating)"
            :type="getIconColor(props.row.rating)"
            pack="fas"
          ></b-icon>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="message"
          :label="$t('omw-feedback-message')"
          centered
          width="500"
          sortable
        >
          <b-icon
            :icon="getMsgIcon(props.row.message)"
            :type="getMsgIconColor(props.row.message)"
            pack="fas"
          ></b-icon>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="name"
          :label="$t('omw-feedback-name')"
          sortable
          >{{ props.row.name }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="email"
          :label="$t('user-admin-email')"
          sortable
          >{{ props.row.email }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="phone"
          :label="$t('omw-feedback-phone')"
          sortable
          >{{ props.row.phone }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="nps"
          :label="$t('omw-feedback-nps')"
          sortable
          >{{ props.row.nps }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="url"
          :label="$t('omw-feedback-url')"
        >
          <a v-if="props.row.url" :href="props.row.url" target="_new">Link</a>
        </b-table-column>
        <b-table-column
          v-slot="props"
          field="platform"
          :label="$t('omw-feedback-device-platform')"
          sortable
          >{{ props.row.platform }}</b-table-column
        >
        <b-table-column
          v-slot="props"
          field="connection"
          :label="$t('omw-feedback-connection')"
          sortable
          >{{ props.row.connection }}</b-table-column
        >

        <b-table-column
          v-slot="props"
          field="product"
          :label="$t('omw-feedback-browser')"
          sortable
          >{{ props.row.product }}</b-table-column
        >
        <template slot="footer">
          <div v-show="feedback && feedback.length > 0" class="has-text-left">
            <span class="has-text-info has-text-weight-bold">
              {{ $t('omw-feedback-total-feedback-label') }}
              {{ feedback.length }}
            </span>
            <br />
            <span class="has-text-info has-text-weight-bold">
              {{ $t('omw-feedback-filtered-feedback-label') }}
              {{ dateFilteredDataSet.length }}
            </span>
          </div>
        </template>
      </b-table>

      <div>
        <p v-if="error">
          {{ $t('omw-feedback-error-msg') }}:
          {{ error }}
        </p>
        <hr />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <npsGauge
          v-if="nps !== null"
          :nps-value="nps"
          :height="'200px'"
          width="450px"
        />
        <strong>
          <p>{{ $t('omw-feedback-average-emotion') }}: {{ averageAll }}</p>
        </strong>
        <strong>
          <p>
            {{ $t('omw-feedback-last-7-days-average') }}:
            {{ averageLastSevenDays ? averageLastSevenDays : 'N/A' }}
          </p>
        </strong>
      </div>
      <div class="column">
        <feedbackPie
          v-show="pieData && pieData.length > 0"
          :pie-data="pieData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { mapGetters } from 'vuex';
import { DateTime, Interval } from 'luxon';
import NPSGauge from './NPSGauge';
import FeedbackPie from './FeedbackPie';

import { getCustomerFeedback } from '@/services/feedback';

function compareRows(a, b) {
  if (a.feedbackDate < b.feedbackDate) {
    return 1;
  } else if (b.feedbackDate < a.feedbackDate) {
    return -1;
  } else {
    return 0;
  }
}

export default defineComponent({
  name: 'FeedbackViewer',
  searchOptions: {
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 2,
    keys: [
      'message',
      'appointmentNumber',
      'resourceId',
      'email',
      'rating',
      'nps',
      'name',
    ],
  },
  components: {
    npsGauge: NPSGauge,
    feedbackPie: FeedbackPie,
  },
  data() {
    return {
      autoRefresh: false,
      refreshInterval: undefined,
      refreshPeriod: 0,
      dateFrom: undefined,
      dateTo: undefined,
      searchCriteria: null,
      searchResults: null,
      files: [],
      error: null,
      isFullPage: true,
      isLoading: false,
      file: null,
      dateFilteredDataSet: [],
      filteredByDate: false,
      keys: [
        'date',
        'appointmentNumber',
        'rating',
        'nps',
        'name',
        'phone',
        'email',
        'message',
        'resourceId',
        'parentResourceId',
        'url',
        'language',
        'token',
        'workZones',
        'product',
        'userAgent',
        'platform',
        'connection',
      ],
    };
  },
  computed: {
    ...mapGetters('storeFeedback', ['feedback']),
    dateTimeFrom() {
      if (!this.dateFrom) return undefined;
      return DateTime.fromJSDate(this.dateFrom);
    },
    dateTimeTo() {
      if (!this.dateTo) return undefined;
      return DateTime.fromJSDate(this.dateTo);
    },
    nps() {
      let detractorCount = 0;
      let promoterCount = 0;
      let totalNpsResponders = 0;

      this.dateFilteredDataSet.forEach((item) => {
        if (item.nps) {
          totalNpsResponders++;
        } else {
          return;
        }
        if (item.nps < 7) {
          detractorCount++;
        } else if (item.nps > 8) {
          promoterCount++;
        }
      });

      if (totalNpsResponders === 0) {
        return 0;
      }
      const detractorPercentage = (detractorCount / totalNpsResponders) * 100;
      const promoterPercentage = (promoterCount / totalNpsResponders) * 100;
      const result = promoterPercentage - detractorPercentage;
      return parseInt(result);
    },
    sortedArrayByDate() {
      if (this.feedback) {
        const feedbackToSort = [...this.feedback]; // eslint-disable-line
        const sortedFeedback = feedbackToSort.sort(compareRows); // eslint-disable-line
        return sortedFeedback;
      } else {
        return [];
      }
    },
    averageAll() {
      let totalFeedback = 0;
      if (this.feedback && this.feedback.length > 0) {
        for (const row of this.feedback) {
          const feedbackVal = row.rating;
          totalFeedback += feedbackVal;
        }
        return (totalFeedback / this.feedback.length).toFixed(2);
      }
      return null;
    },
    averageLastSevenDays() {
      if (this.feedback && this.feedback.length > 0) {
        const filteredData = this.getLastXDays(7, this.sortedArrayByDate);
        if (filteredData.length === 0) {
          return '';
        }
        const sumFunc = (acc, cur) => acc + cur; // Calculates sum
        const total = filteredData.map((el) => el.rating).reduce(sumFunc, 0); // simplifies array to use rating only with map and then uses sumFunc to calculate sum
        const average = (total / filteredData.length).toFixed(2);
        return average;
      }
      return null;
    },
    pieData() {
      const pieDataArray = [];
      let hateCount = 0;
      let dislikeCount = 0;
      let neutralCount = 0;
      let likeCount = 0;
      let loveCount = 0; // eslint-disable-line
      for (const item of this.dateFilteredDataSet) {
        switch (item.rating) {
          case 1: {
            hateCount++;
            break;
          }
          case 2: {
            dislikeCount++;
            break;
          }
          case 3: {
            neutralCount++;
            break;
          }
          case 4: {
            likeCount++;
            break;
          }
          case 5: {
            loveCount++;
            break;
          }
        }
      }
      pieDataArray.push(hateCount);
      pieDataArray.push(dislikeCount);
      pieDataArray.push(neutralCount);
      pieDataArray.push(likeCount);
      pieDataArray.push(loveCount);
      return pieDataArray;
    },
  },
  watch: {
    autoRefresh: {
      immediate: true,
      handler: async function (newValue) {
        if (newValue) {
          await this.fetchAndProcessData();
          this.refreshInterval = setInterval(async () => {
            await this.fetchAndProcessData();
            this.$forceUpdate();
          }, this.refreshPeriod);
        } else {
          if (this.refreshInterval) {
            clearInterval(this.refreshInterval);
          }
        }
      },
    },
  },
  async created() {
    this.dateFrom = DateTime.local().plus({ years: -1 }).toJSDate();
    this.dateTo = DateTime.local().plus({ hours: 6 }).toJSDate();
    this.refreshPeriod = this.$configData.feedback.refreshInterval || 60000;
    await this.fetchAndProcessData();
  },
  beforeDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  },
  methods: {
    getLastXDays(daysToInclude, data) {
      const itemsToInclude = [];
      const includeItemsAfterDate = DateTime.now().plus({
        days: -daysToInclude,
      });

      for (let i = 0; i < data.length; i++) {
        const feedbackDatetime = new DateTime.fromMillis(data[i].feedbackDate);
        const duration = includeItemsAfterDate.diff(feedbackDatetime, 'days');
        const diff = duration.toObject();
        if (diff.days <= 0) {
          itemsToInclude.push(data[i]);
        } else {
          continue;
        }
      }
      return itemsToInclude;
    },
    async fetchAndProcessData() {
      await this.fetchData();
      this.processData();
    },
    processData(evt) {
      let stringFilteredData;
      if (this.searchCriteria && this.searchCriteria.length > 1) {
        stringFilteredData = this.searchResults;
      } else {
        stringFilteredData = this.sortedArrayByDate;
      }

      const interval = Interval.fromDateTimes(
        this.dateTimeFrom,
        this.dateTimeTo,
      );
      const dateFilteredResults = stringFilteredData.filter((feedbackEntry) => {
        const feedbackDate = DateTime.fromMillis(feedbackEntry.feedbackDate);

        if (interval.contains(feedbackDate)) {
          return true;
        }
        return false;
      });
      this.dateFilteredDataSet = dateFilteredResults;
    },
    getMsgIcon(value) {
      if (value) {
        return 'check';
      } else {
        return 'minus';
      }
    },
    getMsgIconColor(value) {
      if (value) {
        return 'is-success';
      } else {
        return 'is-black';
      }
    },
    adjustToLocalTimezone(date) {
      if (!date) return '';
      const datetime = DateTime.fromMillis(date);
      const formattedDate = datetime.toLocaleString(DateTime.DATETIME_MED);
      return formattedDate;
    },
    async fetchData() {
      try {
        this.isLoading = true;
        this.error = null;
        const data = await getCustomerFeedback();
        this.$store.dispatch('storeFeedback/storeFeedbackData', data);
      } catch (err) {
        this.error = err.message;
      } finally {
        this.isLoading = false;
      }
    },
    async search() {
      this.searchResults = await this.$search(
        this.searchCriteria,
        this.feedback,
        this.$options.searchOptions,
      );
    },
    downloadCsv() {
      if (this.feedback && this.feedback.length > 0) {
        const dataForCsv = [];
        this.dateFilteredDataSet.forEach((row) => {
          const theDate = new Date(row.feedbackDate);
          const formattedDate = DateTime.fromJSDate(theDate).toISO();
          const newRow = {};
          Object.assign(newRow, row);
          newRow.date = formattedDate;
          dataForCsv.push(newRow);
        });
        this.exportCsv('feedback_data', dataForCsv, this.keys);
      }
    },
    getIcon(value) {
      switch (value) {
        case 1:
          return 'angry';
        case 2:
          return 'frown';
        case 3:
          return 'meh';
        case 4:
          return 'smile';
        case 5:
          return 'smile-beam';
      }
    },
    getIconColor(value) {
      switch (value) {
        case 1:
          return 'is-danger';
        case 2:
          return 'is-warning';
        case 3:
          return 'is-light';
        case 4:
          return 'is-like';
        case 5:
          return 'is-success';
      }
    },
    rowClass(row) {
      if (row.rating === 1) {
        return 'is-hate';
      }
      if (row.rating === 2) {
        return 'is-dislike';
      }
      if (row.rating === 3) {
        return 'is-neutral';
      }
      if (row.rating === 4) {
        return 'is-like';
      }
      if (row.rating === 5) {
        return 'is-love';
      }
    },
  },
});
</script>

<style scoped lang="scss">
$love: #34b73b;
$like: #2d94f2;
$like-invert: #d26b0d;
$dislike: #f39c12;
$hate: #70221a;

$neutral: #999999;

.feedback-table {
  font-size: 20px;
  width: 100%;
}

.myButton {
  margin-bottom: 10px;
}

tr.is-hate {
  /* background: hsl(9, 99%, 67%) !important; */
  color: $hate !important;
}

tr.is-dislike {
  /* background: hsl(34, 99%, 67%) !important; */
  background: $dislike !important;
  color: white;
}

tr.is-neutral {
  /* background: hsla(33, 7%, 75%, 0.842) !important; */
  background: $neutral !important;
  color: white;
}

tr.is-like {
  /* background: hsla(219, 71%, 45%, 0.842) !important; */
  background: $like !important;
  color: white;
}

tr.is-love {
  /* background: hsla(138, 76%, 46%, 0.842) !important; */
  background: $love !important;
  color: white;
}

.chart-position {
  padding-top: 2rem !important;
}
</style>
